<template>
    <div style="position: relative;" v-if="data">
        <div class="background" />
        <div class="row" style="background: white;">
            <div class="col-md-4 col-12 main-title">
                {{data.title}}
            </div>
            <div class="col-md-8 mt-2 mt-md-0">
                <div class="mb-1 pb-1">
                    <div class="text-description-cookies html-display" v-html="data.description_1" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data: null
            }
        },
        methods:{
            async loadData() {
                try {
                    const res = await this.$http('/api/user/v1/pages/cookies')
                    this.data = res.data
                } catch (err) {
                    this.$printError('Prislo je do napake pri nalaganju podatkov')
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style>
    .text-description-cookies{
        font-size: 17px !important;
    }

    .footer-pages li{
        font-family: 'Encode Sans', sans-serif !important;
        margin-left: 35px;
    }
    .footer-pages p {
        font-family: 'Encode Sans', sans-serif !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }

    .footer-pages a {
        font-family: 'Encode Sans', sans-serif !important;
        color: #72A5D8;
        font-weight: 700;
    }

    .footer-pages h4 {
        font-family: 'Encode Sans', sans-serif !important;
        font-size: 17px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    }

    .footer-pages h1 {
        font-family: 'Encode Sans', sans-serif !important;
        font-size: 1.3rem;        
        font-weight: bold;
    }

    .footer-pages h2 {
        font-family: 'Encode Sans', sans-serif !important;
        font-size: 17px !important;        
        font-weight: normal;
        padding-bottom: 15px;
        margin-bottom: 35px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    }

    .footer-pages iframe {
        height: 330px !important;
        width: 600px !important;
        margin-left: auto;
        margin-right: auto;
    }
    @media only screen and (max-width: 992px) {
        .footer-pages iframe {
            height: 200px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
         }
    }
</style>

<style scoped>

    .background{
        position: absolute;
        top: 50px;
        height: 150px;
        width: 9999px;
        margin-left: -2000px !important;
        margin-right: -2000px !important;
        background: linear-gradient(#72a5d84b, white) !important;
        z-index: -1;
    }

    .main-title{
        font-size: 3rem;
        font-weight: 900;
    }

    .title-content{
        font-weight: bold;
        font-size: 1.3rem;
    }

    .borderBottom{
        border-bottom: 1px solid black;
    }

</style>